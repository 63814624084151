import React, { Component } from 'react'

class Articlepage extends Component {
    constructor(props){
		super(props);
		this.state = {
			articles: [],
			articleID : props.match.params.id
		}
	}

	componentDidMount() {
		fetch("/blog.json")
	  		.then(res => res.json())
			.then((articles) => {
				const articleList = articles.articles;
				const singleArticle = articleList.filter(article => article.Id === this.state.articleID)
				this.setState((state) => {
			        return {
						articles: singleArticle,
			        };
			      }
				);
			});

	}

	render(){
		console.log(this.state.articleID);
		return (
			<div>
				<section className="internal-page-wrapper">
					<div className="container"> 
						{
							this.state.articles.map((article) => {
								let id = article.Id;
								let title = article.Title; 
								let img =  article.img; 
								let Brief =  article.Brief; 
								let details = article.Description1; 
								let description = article.Description2;
								let description1 = article.Description3;
								let img1 = article.img1;
								let img2 = article.img2;
								let img3 = article.img3;

								let articleDiv =
								<div  key={id} >
									<div className="row justify-content-center text-center">
									<div className="article-image">
												<img src={img}/>
											</div>
										<div className="col-12">
											<div className="blog-title">
												{title} 
											</div>
											<div className="blog-brief">
												{Brief}
											</div>
											<div className="blog-description">
												<div dangerouslySetInnerHTML={{ __html: details }}></div>
											</div>
											<div className="article-image">
												<img src={img1}/>
											</div>
											<div className="blog-description">
												<div dangerouslySetInnerHTML={{ __html: description }}></div>
											</div>
											<div className="article-image">
												<img src={img2}/>
											</div>
											<div className="article-image">
												<img src={img3}/>
											</div>
											<div className="blog-description">
												<div dangerouslySetInnerHTML={{ __html: description1 }}></div>
											</div>
											
										</div> 
									</div>
						
								</div>	
								;
								return articleDiv; 
							})
						}
				
					</div>
				</section>
			</div>
		)		
}

}
export default Articlepage
