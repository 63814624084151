import React, {Component}from "react"
import './Header.css';


class Navbar extends Component {
    componentDidMount(){
            const selectElement = (element) =>document.querySelector(element);

            selectElement('.mobile-menu').addEventListener('click' , ()=>{
            selectElement('header').classList.toggle('active')
            })
    }
        render() { 
            return ( 
        <header>
        <div className="container2">
            <nav>
                <ul className="nav-list nav-list-mobile">
                    <li className="nav-item">
                        <div className="mobile-menu">
                            <span className="line line-top"></span>
                            <span className="line line-bottom"></span>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a href="/" className="nav-link">
                            <img src="https://audio-gram.ro/images/audiogram-ico.png" alt="Icon" className="custom-icon" />
                        </a>
                    </li>
                
                </ul>
                {/* <!-- /.nav-list nav-list-mobile -->  */}
                <ul className="nav-list nav-list-larger">
                    <li className="nav-item search-hiden">
                    
                        <input className="nav-link nav-link-searchbar" type="text" 
                            placeholder="&#xF002; Cauta audiogram.ro" 
                            style={{fontFamily:"Arial, FontAwesome"}} />
                    
                    </li>
                    <li className="nav-item nav-item-hidden">
                        <a href="/" className="nav-link">
                            <img src="https://audio-gram.ro/images/audiogram-ico.png" alt="Icon" className="custom-icon" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/locatii" className="nav-link">Locații</a>
                    </li>
                    <li className="nav-item">
                        <a href="/aparate-auditive" className="nav-link">Aparate auditive</a>
                    </li>
                    <li className="nav-item">
                        <a href="/zona-clienti" className="nav-link">Zona clienti</a>
                    </li>
                    <li className="nav-item">
                        <a href="https://business.audiogram.ro/programari" className="nav-link">Programare</a>
                    </li>
                    <li className="nav-item">
                        <a href="/zona-profesionisti" className="nav-link">Zona profesionisti</a>
                    </li>
                    <li className="nav-item">
                        <a href="/despre-noi" className="nav-link">Despre noi</a>
                    </li>
                    <li className="nav-item">
                        <a href="/oracle-chamber" className="nav-link">Oracle Chamber</a>
                    </li>
                    <li className="nav-item">
                        <a href="/blog" className="nav-link">Blog</a>
                    </li>
                    
                    
                    {/* <li className="nav-item">
                            <a href="#" className="nav-link nav-link-bag"></a>
                    </li> */}
                </ul> 
                    
            </nav>

        </div>
    </header>
    )
    }
}
export default Navbar;






// import React, {Component}from "react"
// import Navlink from "./Navlink"
// import logo from "../../images/icons/app.svg"
// import search from "../../images/icons/search-icon-sm.png"
// import cart from "../../images/icons/cart-sm.png"
// import './Header.css'

// className Navbar extends Component {
//     render() { 
//         return ( 
//             <div classNameName="nav-wrapper fixed-top">
//                 <div classNameName="container">
//                     <nav classNameName="navbar navbar-toggleable-sm navbar-expand-md">
//                         <button classNameName="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target=".navbar-collapse">
//                             ☰
//                         </button>
//                         <a classNameName="navbar-brand mx-auto" href="#top"><img src={logo}/></a>

//                         <div classNameName="navbar-collapse collapse">
//                             <ul classNameName="navbar-nav nav-justified w-100 nav-fill">

//                                 <Navlink linkUrl ="#top"linkName="Mac"/>
//                                 <Navlink linkUrl ="#top"linkName="iPhone"/>
//                                 <Navlink linkUrl ="#top"linkName="iPad"/>
//                                 <Navlink linkUrl ="#top"linkName="Watch"/>
//                                 <Navlink linkUrl ="#top"linkName="TV"/>
//                                 <Navlink linkUrl ="#top"linkName="Music"/>
//                                 <Navlink linkUrl ="#top"linkName="Support"/>
//                                 <Navlink linkUrl ="#top" Image = {<img src={search}/>}/>
//                                 <Navlink linkUrl ="#top" Image = {<img src={cart}/>}/>                             
//                             </ul>
//                         </div>
//                     </nav>
//                 </div>
// 	        </div>
//         );     
//     }
// }

// export default Navbar;