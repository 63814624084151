import React, {Component}from "react"


import './Footer.css'
import './Toggel.js'

class Footer extends Component {
    render() { 
                
    return ( 
            <footer className="footer-wrapper">
            <div className="container"> 
                {/* <div className="upper-text-container">
                    <p>1. Trade In: Trade‑in values vary. iPhone 11 and iPhone 11 Pro promotional pricing is after trade‑in of iPhone 8 Plus and iPhone X in good condition. Additional trade‑in values require purchase of a new iPhone, subject to availability and limits. Must be at least 18. Apple or its trade-in partners reserve the right to refuse or limit any Trade In transaction for any reason. In‑store trade‑in requires presentation of a valid, government-issued photo ID local law may require saving this information. Sales tax may be assessed on full value of new iPhone. Additional terms from Apple or Apple’s trade-in partners may apply. Monthly pricing: Available to qualified customers and requires 0% APR, 24-month installment loan with Citizens One or Apple Card Monthly Installments and iPhone activation with AT&T, Sprint, T-Mobile, or Verizon. Taxes and shipping not included. Additional Apple Card Monthly Installments terms are in the <a href="#" > Customer Agreement</a>Additional iPhone Payments terms are <a href={""}> here</a></p>
                    <p>2. Subscription required.<br/>
                    Magic Keyboard sold separately.<br></br>
                    Apple TV+ is $4.99/month after free trial. One subscription per Family Sharing group. Offer good for 3 months after eligible device activation. Plan automatically renews until cancelled. Restrictions and other <a href="https://www.apple.com/promo/">terms </a> apply.</p>
                </div> */}
                
                <div className="my-apple-wrapper"> 
                    Mai multe moduri de a ne găsi: <a href="locatii">Găsiți un cabinet Audiogram</a> sau alt <a href="colaboratori">colabolator</a> langa tine. Sau suna la  0747 046 056.
                </div>
                <div className="copyright-wrapper row">
                    <div className="copyright col-sm-12 order-2 col-md-8 order-md-1 col-lg-4 order-lg-1">
                        Copyright &copy; 2024 Audiogram SRL. All rights reserved.
                    </div>
                    <div className="footer-links-terms  col-sm-12 order-3 col-lg-6 order-lg-2">
                        <ul>
                            <li><a href="politica-cookies">Politica cookies</a></li>
                            <li><a href="termeni-si-conditii">Termeni si conditii</a></li>
                            <li><a href="politica-de-confidentialitate">Politica de confidentialitate</a></li>
                            <li><a href="https://anpc.ro/" target="_blank">Protectia consumatorului</a></li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </footer> 
        );     
    }
}
 
export default Footer;