import React, { Component } from 'react'

class Audiograma extends Component {
    render() { 
                
    return ( 
        <div>
            <section className="internal-page-wrapper">
                    <div className="container"> 
                        <div className="row justify-content-center text-center">
                                    <div className="col-12">
                                            <div className="title-wraper large">
                                                Audiograma prietenoasa:  								
                                            </div>
                                                <div className="zona-description">
                                                    Bine ați venit la Audiogram, locul unde suntem dedicați evaluării și îmbunătățirii sănătății auditive a fiecărui pacient. Testele noastre (audiogramele), sunt realizate cu echipamente de ultimă generație, oferind rezultate precise și detaliate pentru a evalua capacitatea auditivă a fiecărui individ. 								
                                                </div>
                                        
                                                <div className="zona-description">
                                                    Folosind tehnici standardizate, testele noastre acoperă întreaga gamă de frecvențe și intensități sonore, permițând identificarea și diagnosticarea eficientă a oricăror deficiențe auditive.  
                                                </div> 
                                                <div className="zona-description">
                                                Echipa noastră de experți în audiologie vă va ghida prin fiecare etapă a procesului, oferind explicații clare și îndrumare personalizată pentru a vă ajuta să înțelegeți starea auditivă și să luați decizii informate în ceea ce privește îngrijirea și tratamentul auditiv.  
                                                </div>
                                                <div className="zona-description">
                                                    Pentru o evaluare auditivă precisă și profesionistă, puteți face o programare aici: 
                                                </div>     
                                            <h3 className="zona-description pret">Pret: 50 lei</h3>
                                        </div>
                                    </div>
                    </div>
            </section>
        </div>
        
        );     
    }
}
 
export default Audiograma;
