import React, {Component} from 'react';
import './FifthContaint.css'

class FiftyComponent extends Component {
    
    render() { 
        return ( 
            <section className="fifth-heghlight-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="left-side-wrapper col-sm-12 col-md-6">
                            <div className="left-side-container">
                                <div className="top-logo-wrapper">
                                    <div className="title-wraper hub">
                                        Tutoriale Video
                                    </div>
                                </div>

                                <div className="price-wrapper hub">
                                    Invatare interactiva prin videouri instructionale
                                </div>

                                <div className="links-wrapper">
                                    <ul>
                                        <li><a href="/ajutor">Afla mai multe</a></li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                        <div className="right-side-wrapper col-sm-12 col-md-6">
                            <div className="right-side-container">
                                <div className="top-logo-wrapper">
                                    <div className="title-wraper hub">
                                        Zona Profesionisti
                                    </div>
                                </div>

                                <div className="price-wrapper hub">
                                    Playground pentru profesionști
                                </div>

                                <div className="links-wrapper">
                                    <ul>
                                        <li><a href="/zona-profesionisti">Afla mai multe</a></li>
                                    </ul> 
                                </div>
                            </div>
                        </div>					
                    </div>
                </div> 
            </section>
         );
    }
}
 
export default FiftyComponent;