import React, {Component } from 'react';
import Structure from "../Containts/Structure"
import './SecContaints.css';

class SecondContaints extends Component {
    
    render() { 
        return ( 
            <section className="second-hightlight-wrapper">
				<Structure  titleColor="black" title="Invizibilul" 
				colorClass="black" description="Imposibil de mic. Impresionant de puternic."
				/>
				
	        </section>
         );
    }
}
export default SecondContaints;