import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Aparateauditiveploiesti.css'; 
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

class Aparateauditiveploiesti extends Component {

    render() {
        return (
            <div>
                <section className="internal-page-wrapper">
                <div className="row justify-content-center text-center logo-ploiesti-section">
                </div>
                <div className="row justify-content-center text-center ploiesti-section">
                </div>
                    <div className="container">
                    <div className="title-wrapper large contact">
                            Contact
                        </div>
                        <div className="zona-description">
                            <LocationOnIcon /> Ploiești Str. Postei Nr. 35
                        </div>
                        <div className="zona-description">
                            <PhoneIcon /> Tel: 0727 255 994
                        </div>
                        <div className="zona-description">
                            <EmailIcon /> Email: corina_radua@yahoo.com
                        </div>
                        <div className="title-wrapper large despre-noi">
                            Despre noi
                        </div>
                        <div className="zona-description despre-noi">
                        Radu Corina un audiolog cu peste 20 de ani de experienta in teste auditive de la nou-nascuti pana la audiometrii si impedansmetrii, precum si in protezarea auditiva, cu o vasta expertiza in evaluarea si gestionarea sanatatii auditive, extrem de familiarizat cu o gama larga de instrumente si tehnologii pentru testarea si diagnosticarea deficientelor auditive, iar experienta sa ar permite o abordare cuprinzatoare si personalizata a nevoilor fiecarui pacient. Deasemena, ofera consultanta si indrumare in ceea ce priveste solutiile auditive, inclusiv protezele auditive, pentru a imbunatati calitatea vietii pers oanelor cu deficiente auditive.Doamna Radu Corina  detine o clinica auditiva de incredre in Ploiesti. Cu o experienta bogata in diagnosticarea hipoacuziei si gasirea celor mai bune solutii auditive, ea este cunoscuta pentru abilitatea sa de a oferi solutii personalizate pentru fiecare pacient. Firma ei, Alcoradis Prod prin Aparate Auditive Ploiesti, este recunoscuta pentru serviciile de calitate si echipamentele de ultima generatie. 
                        </div>
                        
                    </div>
                        
                </section>
            </div>
        );
    }
}

export default Aparateauditiveploiesti;
